<template>
    <div style="width: 100vw">
        <v-row align="center" style="width: 100vw">
            <v-row justify="space-around" style="margin: 0 0 0 0">
                <v-form
                        :lazy-validation="lazy"
                        :style="this.$vuetify.breakpoint.smAndDown ? 'margin: 0 2vw 0 5vw' : 'width: 100vw'"
                        v-model="valid"
                >
                    <v-container class="mb-3">
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="6"
                            >
                              <v-text-field
                                  v-model="billNr"
                              :label="inputLables['billNumber']"></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="6"
                            >
                                <v-menu
                                    v-if="billNr.length === 0"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="290px"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="consultationDateMenu"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                :label="inputLables['dateFromTo']"
                                                :rules="timeRules"
                                                clearable
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-model="dateRangeText"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            first-day-of-week="1"
                                            :allowed-dates="allowedDays"
                                            color="primary"
                                            range
                                            v-model="createDate"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn @click="sendRequest()" :disabled="!valid" color="primary">Rechnungen anzeigen</v-btn>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card :style="this.$vuetify.breakpoint.smAndDown ? 'margin: 0 2vw 0 5vw' : 'margin-bottom: 10vh'"
                        min-width="70vw">
                    <v-card-title>
                        {{ this.$t('bill.text.preview') }}
                        <v-spacer></v-spacer>
                        <v-text-field
                                :label="this.$t('export.admin.search')"
                                append-icon="mdi-magnify"
                                hide-details
                                single-line
                                v-model="searchTable"
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                            :footer-props="{
            itemsPerPageOptions: [10, 50, 100, -1],
            itemsPerPageText: this.$t('export.admin.itemsPerPage'),
            itemsPerPageAllText: this.$t('export.admin.all'),
        }"
                            :headers="headers"
                            :items="exportData"
                            :loading="isLoadingTable"
                            :no-data-text="this.$t('export.admin.noData')"
                            :no-results-text="this.$t('export.admin.noDataFound')"
                            :search="searchTable"
                            :style="this.$vuetify.breakpoint.smAndDown ? 'margin: 0 2vw 0 5vw; max-width: 85vw' : 'margin-bottom: 10vh; max-width: 95vw'"
                    >
                      <template v-slot:item.id="{ item }">
                        <v-layout justify-center align-center>
                          <v-icon
                              color="primary"
                              @click="downloadInvoice(item.id)"
                          >
                            mdi-download
                          </v-icon>
                        </v-layout>
                      </template>
                    </v-data-table>
                </v-card>
            </v-row>
        </v-row>
      <v-btn fab fixed color="success" right bottom class="mb-6" @click="downloadAllInvoices()"><v-icon>mdi-zip-box</v-icon></v-btn>
    </div>
</template>

<script>
import {
  getBillURL, getFilesOfBillsURL,
  requestMode
} from "@/configBuilder";
    import i18n from "../plugins/i18n";
    import {sessionHandler} from "@/request/sessionHandler";
    import {showAlert} from "@/utils/alertHandler";

    export default {
        name: "Bill",
        data: () => ({
            lazy: false,
            valid: true,
            consultationDateMenu: false,
            createDate: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
            headers: [
              {text: "Rechnungsnummer", value: 'description' },
              {text: "Erstellt am", value: 'createdAt', width: '200px'},
              {text: "Download", value: 'id', width: '150px',  align: 'center',},
            ],
            exportData: [],
            isLoadingTable: false,
            searchTable: '',
            billNr: '',
            searchBill: null,
        }),
        methods: {
            // Preview Request
            async sendRequest() {
                //set Loading true
                this.isLoadingTable = true;
                //init Request Data
              let parameter = {
                'createdAtFrom': this.createDate[0],
                'createdAtTo': this.createDate[1],
              }
                if(this.billNr.length > 0){
                  parameter = {
                    'descriptionContains': this.billNr,
                  }
                }
                // if Session isValid
                await sessionHandler();
                //send request
                await this.$http
                    .get(
                        getBillURL()
                        , {
                            mode: requestMode(),
                            params: parameter,
                            headers: {
                                'Accept': 'application/json',
                                'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.exportData = response.data
                            showAlert(i18n.t('success.loaded'), "success");

                        } else {
                            showAlert(i18n.t('error.api.undefined') + "Bill-sR1", "error");
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                showAlert(i18n.t('warning.unauthorised'), "warning");
                            }
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "Bill-sR2", "error");
                        }
                    })
                    .finally(() => {
                        this.isLoadingTable = false;
                    })
            },

            // Only allow dates from - to + 90
            allowedDays(val) {
                let firstDateMinus = new Date(this.createDate[0] + 'T10:00:00Z');
                let firstDatePlus = new Date(this.createDate[0] + 'T10:00:00Z');
                if (this.createDate[0] && !this.createDate[1]) {
                    firstDateMinus.setDate(firstDateMinus.getDate());
                    firstDatePlus.setDate(firstDatePlus.getDate() + 90);
                    if (firstDateMinus.toISOString().substr(0, 10) > val) {
                        return false
                    }
                    if (firstDatePlus.toISOString().substr(0, 10) < val) {
                        return false
                    }
                }
                return true
            },

          async downloadAllInvoices(){
            let billIds = [];
            this.exportData.forEach(data => {
                billIds.push(data.id);
            });
            await sessionHandler();
            await this.$http
                .get(
                    getFilesOfBillsURL()
                    , {
                      mode: requestMode(),
                      params: {"billIds": billIds.join(',')},
                      headers: {
                        'Accept': 'application/octet-stream',
                        'Content-Type': 'application/octet-stream',
                        'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                      },
                      responseType: 'arraybuffer'
                    }
                )
                .then((d) => {
                  if (d.status === 200) {
                    let filename = "Bill.zip";
                    // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    const blob = new Blob([d.data], {type:'application/octet-stream'});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                      // IE doesn't allow using a blob object directly as link href.
                      // Workaround for "HTML7007: One or more blob URLs were
                      // revoked by closing the blob for which they were created.
                      // These URLs will no longer resolve as the data backing
                      // the URL has been freed."
                      window.navigator.msSaveBlob(blob, filename);
                      return;
                    }
                    // Other browsers
                    // Create a link pointing to the ObjectURL containing the blob
                    const blobURL = window.URL.createObjectURL(blob);
                    const tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.setAttribute('download', filename);
                    // Safari thinks _blank anchor are pop ups. We only want to set _blank
                    // target if the browser does not support the HTML5 download attribute.
                    // This allows you to download files in desktop safari if pop up blocking
                    // is enabled.
                    if (typeof tempLink.download === 'undefined') {
                      tempLink.setAttribute('target', '_blank');
                    }
                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    setTimeout(() => {
                      // For Firefox it is necessary to delay revoking the ObjectURL
                      window.URL.revokeObjectURL(blobURL);
                    }, 100);
                  }
                })
          },
          async downloadInvoice(billId){
            await sessionHandler();
            await this.$http
                .get(
                    getFilesOfBillsURL()
                    , {
                      mode: requestMode(),
                      params: {"billIds": billId},
                      headers: {
                        'Accept': 'application/octet-stream',
                        'Content-Type': 'application/octet-stream',
                        'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                      },
                      responseType: 'arraybuffer'
                    }
                )
                .then((d) => {
                  if (d.status === 200) {
                    let filename = "Bill.zip";
                    // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    const blob = new Blob([d.data], {type:'application/octet-stream'});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                      // IE doesn't allow using a blob object directly as link href.
                      // Workaround for "HTML7007: One or more blob URLs were
                      // revoked by closing the blob for which they were created.
                      // These URLs will no longer resolve as the data backing
                      // the URL has been freed."
                      window.navigator.msSaveBlob(blob, filename);
                      return;
                    }
                    // Other browsers
                    // Create a link pointing to the ObjectURL containing the blob
                    const blobURL = window.URL.createObjectURL(blob);
                    const tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.setAttribute('download', filename);
                    // Safari thinks _blank anchor are pop ups. We only want to set _blank
                    // target if the browser does not support the HTML5 download attribute.
                    // This allows you to download files in desktop safari if pop up blocking
                    // is enabled.
                    if (typeof tempLink.download === 'undefined') {
                      tempLink.setAttribute('target', '_blank');
                    }
                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    setTimeout(() => {
                      // For Firefox it is necessary to delay revoking the ObjectURL
                      window.URL.revokeObjectURL(blobURL);
                    }, 100);
                  }
                })
          },
        },
        computed: {
            errorMessage() {
                return JSON.parse(JSON.stringify(this.$t('error.validation')));
            },
            inputLables() {
                return JSON.parse(JSON.stringify(this.$t('bill.inputField')));
            },
            tableHeaders() {
                return JSON.parse(JSON.stringify(this.$t('export.admin.tableHeader')));
            },
            dateRangeText: {
                get() {
                    return this.createDate.length > 0 ? this.createDate.join(' bis ') : ''

                },
                set() {
                    this.createDate = []
                    return this.createDate.length > 0 ? this.createDate.join(' bis ') : ''

                }
            },
            timeRules() {
                return [
                    this.createDate.length === 2 || this.errorMessage["twoDatesNeeded"],
                ]
            },
            insuranceRules() {
                return [
                    this.insurance.length > 0 || this.errorMessage.required,
                ]
            },
        },
    }
</script>

<style lang="stylus" scoped>
    @media screen and (max-width: 560px)
        .invoiceAdd
            margin-top: 15px
</style>
